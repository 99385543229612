import React from 'react';

const Faq2 = () => {
  return (
    <>
      <meta httpEquiv="refresh" content="0;url=/faq"/>
    </>
  );
};

export default Faq2;
